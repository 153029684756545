@tailwind base;
@tailwind components;
@tailwind utilities;

* {
	box-sizing: border-box;
	margin: 0;
	font-family: "Montserrat", "Roboto", "Oxygen", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: inherit;
}

iframe {
	border-radius: 0 !important;
}

html,
body {
	height: 100vh;
	overflow: hidden;
}

.lineClamp1 {
	display: -webkit-box;
	line-clamp: 1;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
.scrlbr {
	scrollbar-width: auto;
	scrollbar-color: #1976d2 transparent;
}

/* Chrome, Edge, and Safari */
.scrlbr::-webkit-scrollbar {
	width: 8px;
}

.scrlbr::-webkit-scrollbar-track {
	background: transparent;
}

.scrlbr::-webkit-scrollbar-thumb {
	background-color: #1976d2;
	border-radius: 6px;
	border: 3px solid transparent;
}

/* ===== Alt Scrollbar CSS ===== */
/* Firefox */
.scrlbrAlt {
	scrollbar-width: auto;
	scrollbar-color: #25b6dc transparent;
}

/* Chrome, Edge, and Safari */
.scrlbrAlt::-webkit-scrollbar {
	width: 8px;
}

.scrlbrAlt::-webkit-scrollbar-track {
	background: transparent;
}

.scrlbrAlt::-webkit-scrollbar-thumb {
	background-color: #25b6dc;
	border-radius: 6px;
	border: 3px solid transparent;
}

.GRwhite path {
	stroke: #ffffff;
}

.leaflet-popup-content p {
	margin: 0.5rem 0 !important;
}

.leaflet-popup-close-button span {
	font-size: 25px;
}
